import { DefaultButton } from '@fluentui/react/lib/Button';
import { CreateBookingResponseModel } from 'Models/Schedule/Models';
import { useEffect, useState } from 'react';
import { TimeInterval } from '../PickerComponentTypes';
import './../PickerComponentBase.css';


export type TimeSlotConfirmationPickerComponentProps = {
  timeSlot: TimeInterval;
  reservationTimeInSeconds?: number | undefined;
  onConfirm: (e: TimeInterval, cancel: AbortController) => Promise<CreateBookingResponseModel> | undefined;
  onTimeout: () => void;
  onCancelled?: () => void;
}

function TimeSlotConfirmationPickerComponent(props: TimeSlotConfirmationPickerComponentProps) {

  const { timeSlot, onConfirm, onTimeout, reservationTimeInSeconds } = props;
  const [checked, setChecked] = useState<boolean>(false);
  const [reservationHasTimedOut, setReservationHasTimedOut] = useState<boolean>(false);
  const [reservationIsConfirmed, setReservationIsConfirmed] = useState<boolean>(false);

  const [countDownText, setCountDownText] = useState<string>('Confirm');

  let abortController = new AbortController();

  useEffect(() => {
    if (reservationHasTimedOut) return;

    const start = new Date(Date.now());
    const timeOut = new Date(start.getTime()).setSeconds(start.getSeconds() + (reservationTimeInSeconds ?? 60));

    const interval = setInterval(() => {
      var now = new Date().getTime();
      // Find the distance between now and the count down date
      var distance = timeOut - now;

      if (distance > 0) {
        // Time calculations for days, hours, minutes and seconds
        var days = Math.floor(distance / (1000 * 60 * 60 * 24));
        var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        var seconds = Math.floor((distance % (1000 * 60)) / 1000);
        const countDownText = `${days > 0 ? days + 'd ' : ''}${hours > 0 ? hours + 'h ' : ''}${minutes > 0 ? minutes + 'm ' : ''}${seconds > 0 ? seconds + 's' : ''}`
        if (countDownText.length > 0) {
          const text = `Confirm (${countDownText})`;
          setCountDownText(text);
          setReservationHasTimedOut(false);
        }
      }
      else if (!reservationIsConfirmed) {
        setCountDownText('Timeout');
        setReservationHasTimedOut(true);
        onTimeout();
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    }
  }, [reservationTimeInSeconds, reservationHasTimedOut, timeSlot, onTimeout, reservationIsConfirmed]);

  useEffect(() => {
    console.log(checked);
    console.log(reservationIsConfirmed);

  }, [checked, reservationIsConfirmed])
  return (
    <div className='calendar-timeSlot-confirmation-picker-component-container'>
      <div className='calendar-fragment-header'>
        <sub>{timeSlot.from.toLocaleString('da-DK', { month: 'long', year: 'numeric' })}</sub>
        <sub>-</sub>
        <sub>{timeSlot.from.toLocaleString('da-DK', { weekday: 'short', day: '2-digit' })}</sub>
        <sub>kl.</sub>
        <sub>{timeSlot.from.toLocaleString('da-DK', { hour: 'numeric', minute: 'numeric' })}</sub>
        <sub>til</sub>
        <sub>{timeSlot.to.toLocaleString('da-DK', { hour: 'numeric', minute: 'numeric' })}</sub>
      </div>
      <DefaultButton className='toggleButtonStyle' disabled={reservationIsConfirmed || reservationHasTimedOut} toggle={true} checked={checked} onClick={async () => {
        if (!checked && onConfirm) {
          setChecked(!checked);
          try {
            abortController = new AbortController();
            const res = await onConfirm(timeSlot, abortController);
            console.log(res);
            if (res) {
              setReservationIsConfirmed(res.status);
            }

          } catch (error) {
            setReservationIsConfirmed(false);
          }
          // onConfirm(timeSlot, abortController)?.then(res => {
          //   console.log(res);
          //   setReservationIsConfirmed(res.status);
          //   setChecked(!checked);
          // }).catch(err => {
          //   setReservationIsConfirmed(false);
          // });

        };
        setChecked(!checked);
        setReservationIsConfirmed(true);

      }}> {reservationIsConfirmed ? 'Confirmed!' : countDownText}</DefaultButton>
    </div >
  );
}

export default TimeSlotConfirmationPickerComponent;