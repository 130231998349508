import { useState } from 'react';
import './PickerComponentBase.css';
import DayPickerComponent from './DayPicker/DayPickerComponent';
import MonthPickerComponent from './MonthPicker/MonthPickerComponent';
import TimeSlotPickerComponent from './TimeSlotPicker/TimeSlotPickerComponent';
import { DisplayMode, TimeInterval } from './PickerComponentTypes';
import { FontIcon } from '@fluentui/react/lib/Icon';
import { Text } from '@fluentui/react/lib/Text';
import TimeSlotConfirmationPickerComponent from './TimeSlotConfirmationPicker/TimeSlotConfirmationPickerComponent';
import TopicPickerComponent from './TopicPicker/TopicPickerComponent';
import logo from './../../Images/Logo_Positiv_rgb.png';
import TextInputPickerComponent from './TextInputPicker/TextInputPickerComponent';
//import { LookupQueryModel } from 'Models/Lookup/Models';
import { LookupApiClient } from 'Utils/LookupApiClient';
import EmailInputPickerComponent from './EmailInputPicker/EmailInputPickerComponent';
import { CreateBookingQueryModel } from 'Models/Schedule/Models';
import { ScheduleApiClient } from 'Utils/ScheduleApiClient';
import { Link } from '@fluentui/react';
// import { ScheduleApiClient } from 'Utils/ScheduleApiClient';
// import { CreateBookingQueryModel } from 'Models/Schedule/Models';

export type CalenderPickerComponentProps = {
  headerText?: string | undefined;
  topics?: string[] | undefined;
  from?: Date | undefined;
  to?: Date | undefined
  allowPastDateSelections?: boolean | undefined;
  displayMode?: DisplayMode | undefined;
  firstHour?: number | undefined;
  firstMinute?: number | undefined;
  lastHour?: number | undefined;
  excludedHours?: number[] | undefined;
  allowedHours?: number[] | undefined;
  lastMinute?: number | undefined;
  timeSlotBreakMinutes?: number | undefined;
  timeSlotMinutes?: number | undefined;
  onTopicSelectionChanged?: (e: string | undefined) => void;
  onCommentSelectionChanged?: (e: string | undefined) => void;
  onMonthSelectionChanged?: (e: Date | undefined) => void;
  onDaySelectionChanged?: (e: Date | undefined) => void;
  onTimeSlotSelectionChanged?: (e: TimeInterval | undefined) => void;
}

function CalenderPickerComponent(props: CalenderPickerComponentProps) {
  const { topics, from, to, allowPastDateSelections, firstHour, firstMinute, lastHour, lastMinute, timeSlotBreakMinutes, timeSlotMinutes, displayMode, onCommentSelectionChanged, onTopicSelectionChanged, onTimeSlotSelectionChanged, onDaySelectionChanged, onMonthSelectionChanged } = props;

  const [selectedTopic, setSelectedTopic] = useState<string>();
  const [selectedComment, setSelectedComment] = useState<string>();

  const [selectedMonth, setSelectedMonth] = useState<Date>();
  const [selectedDay, setSelectedDay] = useState<Date>();
  const [selectedTimeSlot, setSelectedTimeSlot] = useState<TimeInterval>();

  const [selectedCvr, setSelectedCvr] = useState<string>();
  const [selectedPhone, setSelectedPhone] = useState<string>();
  const [selectedEmail, setSelectedEmail] = useState<string>();
  const [selectedName, setSelectedName] = useState<string>();

  const [cvrInfoElement, setCvrInfoElement] = useState<JSX.Element>();

  let cancelCvrLookupRequest = new AbortController();
  // let cancelCreateBookingRequest = new AbortController();
  console.log(selectedCvr);
  return (
    <div className='calendar-component-container'>

      <div className='calendar-component-fragment logo'>
        <img alt='logo' className='logo' src={logo} />
      </div>

      <div className='calendar-component-fragment text-xlarge'>
        Book en tid
      </div>

      <div className='calendar-component-fragment text-normal'>
        En support medarbejder ringer dig op på det aftalte tidspunkt.
      </div>

      <div className='calendar-component-fragment text-large'>
        Hvad kan vi hjælpe med?
      </div>

      {/* <div className='calendar-fragment-header'>
        {headerText ?? 'Booking'}
      </div> */}

      <div className='calendar-component-fragment'>
        <FontIcon aria-label="topic" iconName="Questionnaire" />
        <h4 id="emne">Emne</h4>
      </div>
      <TopicPickerComponent availableTopics={topics ?? ['Topic1', 'Topic2']} displayMode={displayMode} onSelectionChanged={(d) => {
        setSelectedTopic(d);
        if (!d) {
          setSelectedMonth(undefined);
          setSelectedDay(undefined);
          setSelectedTimeSlot(undefined);
        }
        if (onTopicSelectionChanged) onTopicSelectionChanged(d);
      }} />

      {selectedTopic && (
        <>
          <div className='calendar-component-fragment'>
            <FontIcon aria-label="Street" iconName="CityNext" />
            <h4 id="cvr">CVR nummer(*)</h4>
          </div>
          <TextInputPickerComponent
            regEx={'^([0-9]){5,}$'}
            summery={cvrInfoElement}
            onValidateInput={(s) => {
              // return new Promise<LookupQueryModel>(resolve =>
              //   setTimeout(() => {
              //     resolve({ value: '', hint: '', reason: '', success: true })
              //   }, 2000)
              // );
              cancelCvrLookupRequest = new AbortController();
              return LookupApiClient.lookup('cvr', s ?? '', cancelCvrLookupRequest.signal);
            }}
            onRequestCancel={() => cancelCvrLookupRequest.abort()}
            placeholder='CVR' onSelectionChanged={(d) => {
            }}
            onStateChanged={(e, l) => {
              if (e === 'valid' && l?.value) {
                setSelectedCvr(l.value.cvr);
                console.log(l.value.cvr);
                setCvrInfoElement(
                  <div className='calendar-component-cvr'>
                    <Text variant='medium'>navn</Text>
                    <Text variant='medium'>adresse</Text>
                    <Text variant='medium'>telefon</Text>
                    <Text variant='medium'>Link til cvr <Link target='_blank' href={`https://datacvr.virk.dk/enhed/virksomhed/${l.value.cvr}`} >{l.value.cvr}</Link></Text>


                  </div>
                );
              } else {
                setSelectedCvr(undefined);
                setCvrInfoElement(undefined);
              }
            }} />

          <div className='calendar-component-fragment'>
            <FontIcon aria-label="Fulde navn" iconName="Handwriting" />
            <h4 id="name">Dit fulde navn(*)</h4>
          </div>
          <TextInputPickerComponent placeholder='Fulde navn' required={true} onSelectionChanged={(d) => {
            setSelectedName(d);
          }} />

          {/* <div className='calendar-component-fragment'>
            <FontIcon aria-label="Email" iconName="Mail" />
            <h4 id="email">Din email(*)</h4>
          </div>
          <TextInputPickerComponent placeholder='email' required={true} onSelectionChanged={(d) => {
          }} /> */}

          <div className='calendar-component-fragment'>
            <FontIcon aria-label="Email" iconName="Mail" />
            <h4 id="email">Din email(*)</h4>
          </div>
          <EmailInputPickerComponent mode='email' onValidated={(d, model, value) => {
            setSelectedEmail(value);
          }} />

          <div className='calendar-component-fragment'>
            <FontIcon aria-label="telefon" iconName="Phone" />
            <h4 id="phone1">Dit telefonnummer(*)</h4>
          </div>
          <EmailInputPickerComponent mode='sms' onValidated={(d, model, value) => {
            setSelectedPhone(value);
          }} />

          {/* <div className='calendar-component-fragment'>
            <FontIcon aria-label="telefon" iconName="Phone" />
            <h4 id="phone">Dit telefon nummer(*)</h4>
          </div>
          <TextInputPickerComponent placeholder='telefon' required={true} onSelectionChanged={(d) => {
          }} /> */}

          <div className='calendar-component-fragment'>
            <FontIcon aria-label="commentar" iconName="Comment" />
            <h4 id="kommentar">Kommentar (valgfri)</h4>

          </div>
          <TextInputPickerComponent placeholder='Yderligere information' value={selectedComment} multiline={true} resizable={true} autoAdjustHeight={true} onSelectionChanged={(d) => {
            setSelectedComment(d);
            if (onCommentSelectionChanged) onCommentSelectionChanged(d);
          }} />

        </>
      )
      }

      {
        selectedTopic && (
          <>
            <div className='calendar-component-fragment'>
              <FontIcon aria-label="month" iconName="PlanView" />
              <h4 id="month">Måned</h4>
            </div>

            <MonthPickerComponent from={from} to={to} displayMode={displayMode} onSelectionChanged={(d) => {
              setSelectedMonth(d);
              if (!d) {
                setSelectedDay(undefined);
                setSelectedTimeSlot(undefined);
              }
              if (onMonthSelectionChanged) onMonthSelectionChanged(d);
            }} />
          </>
        )
      }

      {
        selectedTopic && selectedMonth && (
          <>
            <div className='calendar-component-fragment'>
              <FontIcon aria-label="calendarDay" iconName="Calendar" />
              <h4 id="day">Dag</h4>

            </div>
            <DayPickerComponent displayMode={displayMode} from={allowPastDateSelections ? from : new Date(Date.now())} month={selectedMonth} onSelectionChanged={(d) => {
              setSelectedDay(d);
              if (!d) {
                setSelectedTimeSlot(undefined);
              }
              if (onDaySelectionChanged) onDaySelectionChanged(d);
            }} />
          </>
        )
      }

      {
        selectedDay && selectedMonth && (
          <>
            <div className='calendar-component-fragment'>
              <FontIcon aria-label="clock" iconName="Clock" />
              <h4 id="time">Tid</h4>
            </div>
            <TimeSlotPickerComponent displayMode={displayMode} from={allowPastDateSelections ? from : new Date(Date.now())} firstHour={firstHour ?? 0} firstMinute={firstMinute ?? 0} lastHour={lastHour ?? 23} lastMinute={lastMinute ?? 59} timeSlotBreakMinutes={timeSlotBreakMinutes ?? 5} timeSlotMinutes={timeSlotMinutes ?? 10} day={selectedDay} onSelectionChanged={(d) => {
              setSelectedTimeSlot(d);
              if (onTimeSlotSelectionChanged) onTimeSlotSelectionChanged(d);
            }} />
          </>
        )
      }

      {
        selectedTimeSlot && selectedDay && (
          <>
            <div className='calendar-component-fragment'>
              {/* <FontIcon aria-label="calendarDay" iconName="CalendarDay" /> */}
              <FontIcon aria-label="gotoToday" iconName="GotoToday" />
              <h4 id="confirm">Bekræft</h4>
            </div>
            <TimeSlotConfirmationPickerComponent
              timeSlot={selectedTimeSlot}
              onTimeout={() => {
                console.log('Timeout');
              }}
              onConfirm={(e, cancel) => {
                console.log('Confirmed');
                console.log(selectedCvr);

                if (selectedMonth && selectedDay && selectedTimeSlot
                  && selectedTopic && selectedCvr
                  && selectedName && selectedPhone && selectedEmail && selectedComment) {
                  const model: CreateBookingQueryModel = {
                    topic: selectedTopic,
                    year: selectedMonth?.getFullYear(),
                    month: selectedMonth?.getMonth(),
                    day: selectedDay.getDate(),
                    hour: selectedTimeSlot.from.getHours(),
                    minute: selectedTimeSlot.from.getMinutes(),
                    cvr: selectedCvr,
                    phone: selectedPhone,
                    email: selectedEmail,
                    name: selectedName,
                    comments: selectedComment,
                    nonce: ''
                  };

                  console.log(model);
                  const res = ScheduleApiClient.createBooking(model, cancel.signal);
                  return res;
                }
                else {

                  return undefined;

                }
              }} />

          </>
        )
      }
    </div >
  );
}

export default CalenderPickerComponent;