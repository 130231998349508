// © frwrd Corporation. All rights reserved.
import { protectedResources } from './constants';
import { ApiClientT } from './ApiClientT';
import { CreateBookingQueryModel, CreateBookingResponseModel } from 'Models/Schedule/Models';

export const ScheduleApiClient = {
  config: {
    url: `${protectedResources.apiAcsToken.endpoint}/api/calendar/schedule`,
  },
  createBooking: async (model: CreateBookingQueryModel, abortSignal?: AbortSignal): Promise<CreateBookingResponseModel> => {
    const body = model;
    const signal = abortSignal ?? new AbortController().signal;
    const client = new ApiClientT();
    return client.send<CreateBookingResponseModel>(
      'post',
      `${ScheduleApiClient.config.url}/createbooking`,
      signal,
      body
    );
  },
};