import TemplatePageBase from '../template/TemplatePageBase';
import '../../components/Calender/PickerComponentBase.css';

import './ManageBooking.css';

// type PageState = 'none' | 'validated';

function ManageBooking() {

  return (
    <TemplatePageBase showMenu={false} disableHelpMenu={true}>

      <p>ManageBooking</p>
    </TemplatePageBase >
  );
}

export default ManageBooking;