// © frwrd Corporation. All rights reserved.
import { protectedResources } from './constants';
import { ApiClientT } from './ApiClientT';
import { LookupResponseModel, LookupQueryModel } from 'Models/Lookup/Models';

export const LookupApiClient = {
  config: {
    url: `${protectedResources.apiAcsToken.endpoint}/api/getlookup`,
  },
  lookup: async (hint: string, value: string, abortSignal?: AbortSignal): Promise<LookupResponseModel> => {
    const body: LookupQueryModel = {
      hint,
      queryText: value,
    };
    const signal = abortSignal ?? new AbortController().signal;
    const client = new ApiClientT();
    return client.send<LookupResponseModel>(
      'post',
      `${LookupApiClient.config.url}`,
      signal,
      body
    );
  },
};