import { useState } from 'react';
import CalenderPickerComponent from '../../components/Calender/CalenderPickerComponent';
import TemplatePageBase from '../template/TemplatePageBase';
import '../../components/Calender/PickerComponentBase.css';

import './DevPage.css';

type PageState = 'none' | 'validated';

function DevPage() {

  const [pageState, setPageState] = useState<PageState>('validated');
  console.log(setPageState);
  // const token = useAppSelector(selectToken);
  // const email = useAppSelector(selectEmail);

  // const onValidated = (email: string, data: ChallengeResponseModel) => {
  //   if (data.success) {
  //     dispatch(setEmail(email));
  //     dispatch(setToken(data.token));
  //     setPageState('validated');
  //   }
  //   else {
  //     dispatch(setEmail(undefined));
  //     dispatch(setToken(undefined));
  //   }
  // };
  /*
  
  workdays:
  booking: 8-10
  booking: 15-20

  weekend: 8-16
  */

  return (
    <TemplatePageBase showMenu={false} disableHelpMenu={true}>

      {pageState === 'validated' && (
        <CalenderPickerComponent topics={['Tilslutning', 'Opsætning', 'Aktivering', 'Anvendelse', 'Faktura', 'Andet']} firstHour={8} lastHour={19} displayMode='Minimized' />
      )}
    </TemplatePageBase >
  );
}

export default DevPage;