// © frwrd Corporation. All rights reserved.
import { protectedResources } from './constants';
import { ChallengeQueryModel, ChallengeResponseModel, ChallengeReplyModel } from '../Models/Challenge/Models';
import { ApiClientT } from './ApiClientT';

export const ChallengeApiClient = {
  config: {
    url: `${protectedResources.apiAcsToken.endpoint}/api/challenge`,
  },
  requestChallenge: async (challengeType: 'email' | 'sms' | 'none', value: string, abortSignal?: AbortSignal): Promise<ChallengeQueryModel> => {
    const signal = abortSignal ?? new AbortController().signal;
    const client = new ApiClientT();
    return client.send<ChallengeQueryModel>(
      'get',
      `${ChallengeApiClient.config.url}/${challengeType}/${value}`,
      signal
    );
  },
  answerChallenge: async (body: ChallengeReplyModel, abortSignal?: AbortSignal): Promise<ChallengeResponseModel> => {
    const signal = abortSignal ?? new AbortController().signal;
    const client = new ApiClientT();
    return client.send<ChallengeResponseModel>(
      'post',
      ChallengeApiClient.config.url,
      signal,
      body
    );
  },
};