// GitHub pages routing support using HashRoute: 
// https://www.freecodecamp.org/news/deploy-a-react-app-to-github-pages/
import { HashRouter as Router, Route, Routes } from 'react-router-dom';
import { lazy, Suspense } from 'react';
import ContactPage from './Pages/Contact/ContactPage';
import DevPage from './Pages/Dev/DevPage';
import ManageBooking from 'Pages/booking/ManageBooking';

const FeaturesPage = lazy(() => import('./Pages/features/FeaturesPage'));
const SubscriptionsPage = lazy(() => import('./Pages/subscriptions/SubscriptionsPage'));

function App() {

  return (

    <Router>
      <Suspense fallback={<div>Loading...</div>}>
        <Routes>
          <Route path='/' element={<FeaturesPage />} />
          <Route path='/subscriptions' element={<SubscriptionsPage />} />
          <Route path='/contact' element={<ContactPage />} />

          {/* <Route path='/tryme' element={<TryMePage />} /> */}
          <Route path='/dev' element={<DevPage />} />
          <Route path='/dev2' element={<ManageBooking />} />


        </Routes>
      </Suspense>
    </Router>
  );
}

export default App;