import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { appMode } from './Utils/constants';
import ReactDOM from 'react-dom';
//import { createRoot } from 'react-dom/client';

// import { initializeIcons } from '@fluentui/react/lib/Icons';
import { initializeIcons/*, registerIcons*/ } from '@fluentui/react';
import { GlobalDebug } from './Utils/GlobalDebug';
//import { DEFAULT_COMPONENT_ICONS } from '@azure/communication-react';


GlobalDebug(appMode.debugOn, appMode.suppressAll);

// initializeIcons('https://static2.sharepointonline.com/files/fabric/assets/icons/', { disableWarnings: true });
initializeIcons(/* optional base url */);
// registerIcons(/*{ icons: DEFAULT_COMPONENT_ICONS }*/);
//registerIcons({ icons: '*' });


// const container = document.getElementById('root');
//const root = createRoot(container!);
require('events').defaultMaxListeners = 70;

ReactDOM.render(
  //root.render(<React.StrictMode>
  <App />
  //</React.StrictMode >
  , document.getElementById('root'));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
