import { DefaultButton, FontIcon, Spinner, SpinnerSize, Text, Link } from '@fluentui/react';
import { TextField } from '@fluentui/react/lib/TextField';
import { ChallengeReplyModel, ChallengeResponseModel } from 'Models/Challenge/Models';
import { LookupResponseModel } from 'Models/Lookup/Models';
import { useCallback, useEffect, useState } from 'react';
import { ChallengeApiClient } from 'Utils/ChallengeApiClient';
import './../PickerComponentBase.css';

export type EmailInputPickerComponentProps = {
  mode: 'sms' | 'email';
  regEx?: string | null;
  value?: string | undefined;
  multiline?: boolean | undefined;
  resizable?: boolean | undefined;
  required?: boolean | undefined;
  placeholder?: string | undefined;
  prefix?: string | undefined;
  autoAdjustHeight?: boolean | undefined;
  iconName?: string | undefined;
  validIconName?: string | undefined;
  onValidateInput?: (e: string | undefined) => Promise<LookupResponseModel>;
  onRequestCancel?: () => void;
  summery?: JSX.Element | undefined;
  onValidated: (email: string, data: ChallengeResponseModel, value: string) => void

}

type State =
  "submit-email" | "submitted-email" | "accepted-email" | "error-email" | "retry-email" |
  "submit-challenge" | "submitted-challenge" | "accepted-challenge" | "error-challenge";



function EmailInputPickerComponent(props: EmailInputPickerComponentProps) {

  const [abortController, setAbortController] = useState<AbortController | undefined>(undefined);

  const [state, setState] = useState<State>('submit-email');

  const [errorMessage, setErrorMessage] = useState('');

  const [emailValue, setEmailValue] = useState('');
  const [verificationCode, setVerificationCode] = useState('');
  const [nonce, setNonce] = useState('');

  const validateEmail = (email: string): boolean => {
    if (props.mode === 'email') {
      const regEx = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return email.length > 0 && regEx.test(email);
    }
    else {
      return email.length > 0;
    }
  };

  const onRequestCancel = useCallback((e: 'email' | 'challenge') => {
    if (e === 'email') {
      setState('submit-email');
    }
    if (e === 'challenge') {
      setState('submit-challenge');
    }
    if (abortController) abortController.abort();
  }, [abortController]);

  const requestChallenge = useCallback(() => {
    const ac = new AbortController();
    setAbortController(ac);
    // setLoading(true);
    setVerificationCode('');
    setErrorMessage('');
    setState('submitted-email');

    ChallengeApiClient.requestChallenge(props.mode, emailValue, ac.signal)
      .then(model => {
        if (model.success) {
          setState('accepted-email');
          setErrorMessage('');
          setNonce(model.nonce);
        } else {
          setState('error-email');
          setErrorMessage(model.reason);
          setNonce('');
        }
      }).catch(err => {
        if (err.name === 'AbortError') {
          setErrorMessage('');
        }
        else if (err.name === 'TypeError') {
          setState('error-email');
          setErrorMessage("Sorry! Please try again.");
        }
        else {
          setState('error-email');
          setErrorMessage(`Invalid! please try again.`);
        }
        setNonce('');
      }).finally(() => {
        setAbortController(undefined);
      })

  }, [emailValue, props.mode]);

  const submitVerificationCode = useCallback(() => {
    const ac = new AbortController();
    setAbortController(ac);
    setState('submitted-challenge');
    // setLoading(true);
    setErrorMessage('');

    const answer: ChallengeReplyModel = {
      nonce: nonce,
      value: emailValue,
      replyValue: verificationCode
    };

    ChallengeApiClient.answerChallenge(answer, ac.signal)
      .then(model => {
        if (model.success) {
          setState('accepted-challenge');
          setErrorMessage('');
          props.onValidated(emailValue, model, emailValue);
        } else {
          setState('error-challenge');
          setErrorMessage(model.reason);
        }
      })
      .catch(err => {
        if (err.name === 'AbortError') {
          setErrorMessage('');
        }
        else if (err.name === 'TypeError') {
          setState('error-challenge');
          setErrorMessage("Sorry! Please try again.");
        }
        else {
          setState('error-challenge');
          setErrorMessage(`Ugyldig verifikationskode, prøv igen.`);
        }
      })
      .finally(() => {
        setAbortController(undefined);
      });
  }, [verificationCode, emailValue, nonce, props]);

  // const reset = useCallback(() => {
  //   setState('submit-email');
  //   setErrorMessage('');
  //   setEmailValue('');
  //   setVerificationCode('');
  //   setNonce('');
  //   setLoading(false);
  // }, []);

  useEffect(() => {
    return () => {
      if (abortController) {
        abortController.abort();
      }
    }
  }, [abortController])

  return (
    <>
      {(state.endsWith('-email') || state.endsWith('-challenge')) && (
        <>
          <div className='calendar-email-input-picker-component-container'>
            <TextField
              placeholder={props.mode === 'email' ? 'email' : 'telefon'}
              type={props.mode}
              value={emailValue}
              disabled={state === 'submitted-email' || state.endsWith('-challenge') || state === 'accepted-challenge' || state === 'accepted-email'}
              errorMessage={(state === 'error-email' ? errorMessage : '')}
              onChange={(_, newValue) => {
                setState('submit-email');
                setEmailValue(newValue ?? '');
              }}
            />

            {(state === 'submit-email' || state === 'error-email') && (
              <DefaultButton
                className='buttonStyleDefault'
                disabled={!validateEmail(emailValue)}
                onClick={requestChallenge}>
                <FontIcon aria-label={'Unlock'} iconName={'Unlock'} />
              </DefaultButton>
            )}

            {state.startsWith('submitted-email') && (
              <Spinner size={SpinnerSize.large} onClick={() => {
                onRequestCancel('email');
              }} />
            )}

            {state === 'error-email' && (
              <div className='calendar-email-input-picker-component-container'>
                <Text>{props.mode === 'email' ? 'Ukendt email' : 'Ukendt telefonnummer'}</Text>
              </div>
            )}

            {(state.startsWith('accepted-') || state.endsWith('-challenge')) && (
              <DefaultButton
                className='buttonStyleDefault'
                disabled={state === 'accepted-challenge'}
                onClick={() => {
                  setState('submit-email');
                }}>
                <FontIcon aria-label={'Lock'} iconName={'Lock'} />
              </DefaultButton>
            )}
          </div>
        </>
      )}

      {((state.endsWith('-challenge') || state === 'accepted-email') && (state !== 'accepted-challenge')) && (
        <div className='calendar-email-input-picker-component-container'>
          <>
            <TextField
              placeholder='verificationskode'
              type='text'
              value={verificationCode}
              // disabled={state === 'accepted-challenge'}
              errorMessage={errorMessage}
              onChange={(_, newValue) => {
                setState('accepted-email');
                setVerificationCode(newValue ?? '');
              }}
            />

            {(state === 'submit-challenge' || state === 'accepted-email' || state === 'error-challenge') && (
              <DefaultButton
                className='buttonStyleDefault'
                disabled={!verificationCode}
                onClick={submitVerificationCode}>
                <FontIcon aria-label={'Unlock'} iconName={'Unlock'} />
              </DefaultButton>
            )}

            {state.startsWith('submitted-') && (
              <Spinner size={SpinnerSize.large} onClick={() => {
                onRequestCancel('challenge');
              }} />
            )}

            {props.mode === 'email' && (
              <div className='calendar-email-input-picker-component-container'>
                <Text variant='medium'>Der er sendt en verificationskode til din email.</Text>
                <Text variant='medium'>Koden bruges til at sikre at vi sender bekræftelsen til din email.</Text>
                <div className='calendar-email-input-picker-component-container'>
                  <Text variant='medium'>Jeg har ikke modtaget en kode, send en ny <Link onClick={() => {
                    requestChallenge();
                  }}>kode</Link>.</Text>
                </div>
              </div>
            )}

            {props.mode === 'sms' && (
              <div className='calendar-email-input-picker-component-container'>
                <Text variant='medium'>Der er sendt en verificationskode til dit telefonnummer.</Text>
                <Text variant='medium'>Koden bruges til at sikre at vi sender bekræftelsen til dit telefonnummer.</Text>
                <div className='calendar-email-input-picker-component-container'>
                  <Text variant='medium'>Jeg har ikke modtaget en kode, send en ny <Link onClick={() => {
                    requestChallenge();
                  }}>kode</Link>.</Text>
                </div>
              </div>
            )}
          </>
        </div>
      )}

      {state === 'accepted-challenge' && props.mode === 'email' && (
        <div className='calendar-email-input-picker-component-container'>
          <>
            <div className='calendar-email-input-picker-component-container'>
              <Text variant='medium'>Brug en anden <Link onClick={() => {
                setState('submit-email');
              }}>email</Link>.</Text>
            </div>
          </>
        </div>
      )}

      {state === 'accepted-challenge' && props.mode === 'sms' && (
        <div className='calendar-email-input-picker-component-container'>
          <>
            <div className='calendar-email-input-picker-component-container'>
              <Text variant='medium'>Brug et andet <Link onClick={() => {
                setState('submit-email');
              }}>telefonnummer</Link>.</Text>
            </div>
          </>
        </div>
      )}
    </>
  );
}

export default EmailInputPickerComponent;