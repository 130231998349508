import { DefaultButton, FontIcon, Spinner, SpinnerSize } from '@fluentui/react';
import { TextField } from '@fluentui/react/lib/TextField';
import { LookupResponseModel } from 'Models/Lookup/Models';
import { useEffect, useState } from 'react';
import './../PickerComponentBase.css';

export type TextInputPickerComponentProps = {
  regEx?: string | null;
  value?: string | undefined;
  multiline?: boolean | undefined;
  resizable?: boolean | undefined;
  required?: boolean | undefined;
  placeholder?: string | undefined;
  prefix?: string | undefined;
  autoAdjustHeight?: boolean | undefined;
  iconName?: string | undefined;
  validIconName?: string | undefined;
  onStateChanged?: (e: state, l: LookupResponseModel | undefined) => void;
  onValidateInput?: (e: string | undefined) => Promise<LookupResponseModel>;
  onSelectionChanged: (e: string | undefined) => void;
  onRequestCancel?: () => void;
  summery?: JSX.Element | undefined;
}

declare type state = 'unknown' | 'validate' | 'validating' | 'valid' | 'invalid';

function TextInputPickerComponent(props: TextInputPickerComponentProps) {

  const { onSelectionChanged, onValidateInput, onStateChanged, onRequestCancel, regEx, summery, validIconName, multiline, value, resizable, placeholder, required, prefix, autoAdjustHeight, iconName } = props;

  const [state, setState] = useState<state>('unknown');
  const [text, setText] = useState<string | undefined>(undefined);
  const [lookupResponseModel, setLookupResponseModel] = useState<LookupResponseModel | undefined>(undefined);

  const regExValid = (): boolean => {
    if (!regEx) {
      return false;
    }
    else {
      const regexp = new RegExp(regEx);
      return !regexp.test(text ?? '');
    }
  }

  useEffect(() => {
    setText(value);
    setState('unknown');
  }, [value]);

  return (
    <>
      <div className='calendar-text-input-picker-component-container'>
        <TextField value={text} disabled={state === 'validating' || state === 'valid'} autoAdjustHeight={autoAdjustHeight} prefix={prefix} placeholder={placeholder} required={required} multiline={multiline} resizable={resizable} onChange={
          (e, t) => {
            setText(t);
            console.log(t);
            if (onValidateInput) setState('unknown');
            if (onValidateInput && onStateChanged) onStateChanged('unknown', lookupResponseModel);
            onSelectionChanged(t)
          }
        } />

        {onValidateInput && (state === 'invalid' || state === 'unknown') && (
          <DefaultButton className='buttonStyleDefault' disabled={regExValid()} onClick={() => {
            setState('validating');
            if (onStateChanged) onStateChanged('validating', lookupResponseModel);
            console.log(text);

            onValidateInput(text).then(v => {
              setLookupResponseModel(v);
              console.log(v);

              if (v.success === true) {
                setState('valid');

                if (onStateChanged) onStateChanged('valid', v);
              }
              else {
                setState('invalid');
                if (onStateChanged) onStateChanged('invalid', v);
              }
            }).catch(reason => {
              setLookupResponseModel(undefined);
              setState('invalid');
              if (onStateChanged) onStateChanged('invalid', undefined);
            })
          }}>
            <FontIcon aria-label={iconName ?? 'Unlock'} iconName={iconName ?? 'Unlock'} />
          </DefaultButton>
        )}

        {state === 'validating' && (
          <Spinner size={SpinnerSize.large} onClick={() => {
            if (onRequestCancel) onRequestCancel();
          }} />
        )}

        {state === 'valid' && (
          <DefaultButton className='buttonStyleDefault' onClick={() => {
            setState('unknown');
            if (onStateChanged) onStateChanged('unknown', lookupResponseModel);
          }}>
            <FontIcon aria-label={validIconName ?? 'Lock'} iconName={validIconName ?? 'Lock'} />
          </DefaultButton>
        )}
      </div>
      {summery && (
        <div className='calendar-text-input-picker-component-container'>
          {summery}
        </div>
      )}
    </>
  );
}

export default TextInputPickerComponent;